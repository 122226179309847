import React from "react"
import { MdArrowForward } from "react-icons/md"

import { linkStyles } from "../utils/styles"

const ReadMore = () => (
  <p
    css={theme => ({
      color: theme.colors.lilac,
      display: `inline-block`,
      ...linkStyles(theme),
    })}
    className="read-more"
  >
    Read more
    {` `}
    <MdArrowForward
      css={theme => ({
        verticalAlign: `middle`,
        lineHeight: theme.lineHeights.body,
        marginLeft: `.2em`,
      })}
    />
  </p>
)

export default ReadMore
