import React from "react"
import PropTypes from "prop-types"
import { Link } from "gatsby"

import RevealOnScroll from "../../components/reveal-on-scroll"
import HeadlineSmallCaps from "../../components/headline-small-caps"
import ReadMore from "../../components/read-more"
import LazySVGImg from "../../components/lazy-svg"

import { getLink } from "../../utils/navigation"
import { rhythm } from "../../utils/typography"
import { bodyCopy, titleStyles } from "../../utils/styles"

const styles = {
  container: theme => ({
    paddingTop: theme.space[5],
    position: `relative`,
    zIndex: 1,
  }),
  link: theme => ({
    textDecoration: `none`,
    "&:hover h3": {
      color: theme.colors.gatsby,
    },
    "&:hover .read-more": {
      color: theme.colors.gatsby,
    },
  }),
  column: theme => ({
    paddingBottom: theme.space[10],
    [theme.mediaQueries.desktop]: {
      flex: `0 0 50%`,
      paddingRight: theme.space[7],
      paddingTop: theme.space[10],
      paddingBottom: theme.space[10],
      "&:nth-of-type(2n+0)": {
        paddingLeft: theme.space[7],
        paddingRight: 0,
      },
    },
  }),
  keyvisual: theme => ({
    position: `absolute`,
    left: -200,
    top: 0,
    "& .SVGInline-svg": { width: 160 },
    [theme.mediaQueries.desktop]: {
      "& .SVGInline-svg": { width: 200 },
      left: -240,
    },
  }),
  titleStylesCustom: theme => ({
    fontWeight: `bold`,
    [theme.mediaQueries.desktop]: {
      maxWidth: 480,
      marginBottom: theme.space[10],
    },
  }),
  titleStylesCustomColumn: theme => ({
    [theme.mediaQueries.desktop]: {
      maxWidth: 360,
      fontSize: theme.fontSizes[6],
    },
  }),
  bodyCopyCustom: theme => ({
    [theme.mediaQueries.desktop]: {
      fontSize: theme.fontSizes[1],
      maxWidth: rhythm(16),
    },
  }),
}

const Single = ({ section }) => (
  <Link to={getLink(section.associatedPage)} css={styles.link}>
    <h3 css={theme => [titleStyles(theme), styles.titleStylesCustom(theme)]}>
      {section.associatedPage.name}
    </h3>
    <div css={theme => bodyCopy(theme)}>
      {!!section.text && (
        <div
          dangerouslySetInnerHTML={{
            __html:
              section.text.childMarkdownRemark &&
              section.text.childMarkdownRemark.html,
          }}
        />
      )}
      <ReadMore />
    </div>
  </Link>
)

Single.propTypes = {
  section: PropTypes.object.isRequired,
}

const Column = ({ section }) => (
  <div css={styles.column}>
    <Link to={getLink(section.associatedPage)} css={styles.link}>
      <h3
        css={theme => [
          titleStyles(theme),
          styles.titleStylesCustom(theme),
          styles.titleStylesCustomColumn(theme),
        ]}
      >
        {section.associatedPage.name}
      </h3>
      <div css={theme => [bodyCopy(theme), styles.bodyCopyCustom(theme)]}>
        <div
          dangerouslySetInnerHTML={{
            __html:
              section.text &&
              section.text.childMarkdownRemark &&
              section.text.childMarkdownRemark.html,
          }}
        />
        <ReadMore />
      </div>
    </Link>
  </div>
)

Column.propTypes = {
  section: PropTypes.object.isRequired,
}

const GuidesSection = ({ page }) => {
  const { sections } = page
  let sectionsToRender = []

  // flatten section.featuresList, noob version
  sections &&
    sections.forEach(section => {
      !section.featuresList
        ? sectionsToRender.push(section)
        : section.featuresList.forEach(featuresList => {
            sectionsToRender.push(featuresList)
          })
    })

  // filter sections that do not have an associated page
  // or link to "Support" (moar noob)
  sectionsToRender = sectionsToRender.filter(
    section =>
      section.associatedPage && section.associatedPage.slug !== `support`
  )

  if (sectionsToRender.length === 0) {
    return null
  }

  return (
    <RevealOnScroll>
      <div css={styles.container}>
        {page.keyvisual && (
          <div css={styles.keyvisual}>
            <LazySVGImg src={`${page.keyvisual.name}.svg`} />
          </div>
        )}
        <HeadlineSmallCaps css={theme => ({ color: theme.colors.gatsby })}>
          {page.name}
        </HeadlineSmallCaps>
        <div css={{ display: `flex`, flexWrap: `wrap` }}>
          {sectionsToRender.length === 1 && (
            <Single section={sectionsToRender[0]} />
          )}
          {sectionsToRender.length > 1 &&
            sectionsToRender.map((section, i) => (
              <Column section={section} index={i} key={i} />
            ))}
        </div>
      </div>
    </RevealOnScroll>
  )
}

GuidesSection.propTypes = {
  page: PropTypes.object.isRequired,
}

export default GuidesSection
